
import './App.css';
import  Navbar  from "./components/Navigation/Navbar";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Services from "./components/Services/Services";
import Login from './components/Login_Signup/Login';
import Packages from './components/Packages/Packages';
import Blogs from './components/Blogs/create_blog';
// import png1d from "./slides/slide1_dark.png"
import './components/Slider/slider.css'
import './components/Home/home.css'
import './components/Login_Signup/Login.css'
import './components/About/about.css'
import './components/Services/service.css'
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import React, { useState } from 'react'
import Footer from './components/Navigation/Footer';

function App() {
  const [mode,setModeState] = useState('light')
  const [modeText,setModeText] = useState('Enable Dark Mode')
  const toggle = () =>{
    var headings = document.querySelectorAll(".heading")
    var sections = document.querySelectorAll(".section")
    var scaps = document.querySelectorAll(".scap")
    var ecaps = document.querySelectorAll(".ecap")
    var btns = document.querySelectorAll(".btn")
    // var slide1 = document.querySelector("#slide1")
    if(mode === "light"){
        setModeText('Disable Dark Mode')
        setModeState('dark')
        // document.querySelector(".wave").style.backgroundColor = 'rgba(127, 160, 231, 0.719)'
        document.querySelector(".body").classList.replace('bg','bg-dark')
        sections.forEach(function(element){
          element.classList.add('section-dark')
        })
        headings.forEach(function(element){
          element.classList.replace('content-header','content-header-dark')
        })
        scaps.forEach(function(element){
          element.classList.replace("start-cap","start-cap-dark")
        })
        ecaps.forEach(function(element){
          element.classList.replace("end-cap","end-cap-dark")
        })
        btns.forEach(function(element){
          element.classList.replace("btn-success","btn-dark")
        })

    }else{
        setModeText('Enable Dark Mode')
        setModeState('light')
        document.querySelector(".body").classList.replace('bg-dark','bg')
        sections.forEach(function(element){
          element.classList.remove('section-dark')
        })
        headings.forEach(function(element){
          element.classList.replace('content-header-dark','content-header')
        })
        scaps.forEach(function(element){
          element.classList.replace("start-cap-dark","start-cap")
        })
        ecaps.forEach(function(element){
          element.classList.replace("end-cap-dark","end-cap")
        })
        btns.forEach(function(element){
          element.classList.replace("btn-dark","btn-success")
        })
    }
  }
  

  return (
    <>
      <Router>
        
        <Navbar mode={mode} toggle={toggle} text={setModeState} modeText={modeText}/>
        {/* <PillsExample style={{marginTop:'10%'}}/> */}
        {/* <div className="wave"></div>
        <div className="wave"></div>
        <div className="wave"></div>
        <div className="wave"></div> */}
        <div className="container-fluid body bg" style={{color:mode==='dark'?'white':'black',padding:'0 0 -2 0'}}>
          
          <Routes>
            <Route exact path="/" element={<Home mode={mode} />}></Route>
            <Route path="/about" element={<About mode={mode} />}></Route>
            <Route path="/services" element={<Services mode={mode} />}></Route>
            <Route path="/packages" element={<Packages mode={mode} />}></Route>
            <Route path="/blogs" element={<Blogs mode={mode} />}></Route>
            {/* <Route path="/register" element={<Signup mode={mode} />}></Route> */}
            {/* <Route>
              <NoMatch />
            </Route> */}
          </Routes>
        </div>
        <Footer mode={mode} toggle={toggle}/>
        
      </Router>
    </>
  );
}

export default App;
