import React, { useEffect, useState } from 'react';
import axios from 'axios';

export default function Packages() {
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('http://localhost:3001/api/packages');
        setPlans(response.data);
      } catch (error) {
        console.error(`Error fetching pricing plans: ${error}`);
      }
    };

    fetchData();
  }, []);

  const transposedPlans = plans[0] ? Object.keys(plans[0]).map((key) => plans.map((plan) => plan[key])) : [];
  const keys = plans[0]
    ? Object.keys(plans[0]).filter((key) => !['PlanName', 'PlanID'].includes(key))
    : [];

    const splitFeatures = (value) => {
      if (value.includes(',')) {
        return value.split(',').map((feature, index) => (
          <tr key={index}>
            <td>{feature.trim()}</td>
          </tr>
        ));
      } else {
        return <tr><td>{value.trim()}</td></tr>;
      }
    };
  
  return (
    <>
      <div className='table' style={{marginTop:'3.7%', minHeight:'80vh'}}>
        
          <div>
            <h2>Pricing Plans</h2>
            <table>
              <thead>
                <tr>
                  <th>Property</th>
                  {plans.map((plan, index) => (
                    <th key={index}>{plan.PlanName}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
              {keys.map((key, keyIndex) => (
                  <>
                  <tr key={keyIndex}>
                    <td>{key === 'price' ? 'Price' : key.charAt(0).toUpperCase() + key.slice(1)}</td>
                    {plans.map((plan, index) => (
                      <td key={index}>
                        {key === 'Features' ? (
                          <table>
                            <tbody>
                              {splitFeatures(plan[key])}
                            </tbody>
                          </table>
                        ) : (
                          plan[key]
                        )}
                      </td>
                    ))}
                  </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
        
      </div>
    </>
  )
}
