import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import '../Blogs/blogs.css'

export default function App() {
  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };
  return (
    <>
        <div style={{marginTop:'7%', alignContent:'center', alignItems:'center'}}>
          <form action="" style={{marginLeft:'23vw',border:'grey',width:'52.5vw'}}>
            <input type="text" id="title" className='blog_title' name="title" placeholder="Enter title"/>
            {/* <textarea id="myTextarea" name="content" style={{height: '65vh'}}></textarea> */}
            <Editor
                className='blog_editor'
                apiKey='op289qlgcc59spk7x59pu9dq4b8100hl9xs3kibrjh4bcp02'
                onInit={(evt, editor) => editorRef.current = editor}
                initialValue="<p>This is the initial content of the editor.</p>"
                init={{
                    title: true,
                    height: 700,
                    width:1000,
                    plugins: "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
                    toolbar: "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
                    image_title: true,
                    images_upload_url: '/upload_image', // Flask endpoint for image upload
                    automatic_uploads: true,
                    images_reuse_filename: true,
                    convert_urls: false,
                    tinycomments_mode: 'embedded',
                    tinycomments_author: 'Author name',
                    mergetags_list: [
                      { value: 'First.Name', title: 'First Name' },
                      { value: 'Email', title: 'Email' },
                    ],
                    ai_request: (request, respondWith) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),
                    setup: function (editor) {
                      editor.on('change', function () {
                        // Handle the content change here
                        console.log('Content changed:', editor.getContent());
                      });
                    }
                    // menubar: true,
                    // plugins: "mentions anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed permanentpen footnotes advtemplate advtable advcode editimage tableofcontents mergetags powerpaste tinymcespellchecker autocorrect a11ychecker typography inlinecss",
                    // toolbar: "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
                    //     content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
            />
              <button onClick={log}class="btn waves-effect waves-light items-center" style={{color: 'black', backgroundColor:'darkorange'}} type="submit" name="action">Create Post
              </button>
            </form>
        </div>
    </>
  );
}