import React, { useState, useEffect } from 'react';
import logo from '../../media/final_logo.gif';
import sun from './sun1.gif';
import moon from './night.gif'
import { Link } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

export default function Navbar(props) {
    const [activeMenu, setActiveMenu] = useState(null);
    const { loginWithRedirect, user, isAuthenticated, isLoading, logout } = useAuth0();
    
    const handleMenuClick = (menuName) => {
        setActiveMenu(menuName);
        localStorage.setItem('activeMenu', menuName);
    };

    useEffect(() => {
        const storedActiveMenu = localStorage.getItem('activeMenu');
        // if (storedActiveMenu) {
        //     setActiveMenu(storedActiveMenu);
        // }
        const currentPath = window.location.pathname;

        if (currentPath === '/') {
            setActiveMenu('Home');
        } else if (currentPath === '/about') {
            setActiveMenu('About');
        } else if (currentPath === '/Contact') {
            setActiveMenu('Contact');
        }
    }, []);

    return (
        <>
            <nav className={`navbar navbar-expand-lg navbar-${props.mode === 'dark' ? 'white' : 'black'} fixed-top`} style={{ backgroundColor: props.mode === 'dark' ? '#A3C1C0' : 'white', boxShadow: '0 4px 8px 8px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', fontWeight: 800 }}>
                <div className="container-fluid">
                    <a className="navbar-brand" href="/">
                        <img src={logo} alt='Unreal Automation' style={{ height: 35 + 'px' }} />
                    </a>
                    <div className="vl" style={{ borderLeft: '2px solid green', height: '50px', position: 'Relative', marginLeft: '-3px' }}></div>
                    <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel" style={{ backgroundColor: props.mode === 'dark' ? '#A3C1C0' : 'white'}}>
                        <div className="offcanvas-header">
                            <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
                                <img src={logo} height={'40px'} alt='' />
                            </h5>
                            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body" style={{ justifyContent:'center'}}>
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <a className={`nav-link ${activeMenu === 'Home' ? 'active-nav' : ''}`} style={{color:'black'}} onClick={() => handleMenuClick('Home')} id='home' href="/">Home</a>
                                </li>
                                <li className="nav-item">
                                    <Link className={`nav-link ${activeMenu === 'About' ? 'active-nav' : ''}`} style={{color:'black'}} onClick={() => handleMenuClick('About')} id='about' to="/about">About Us</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={`nav-link ${activeMenu === 'Services' ? 'active-nav' : ''}`} style={{color:'black'}} onClick={() => handleMenuClick('Services')} id='services' to="/services">Services</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={`nav-link ${activeMenu === 'Packages' ? 'active-nav' : ''}`} style={{color:'black'}} onClick={() => handleMenuClick('Packages')} id='package' to="/packages">Packages</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={`nav-link ${activeMenu === 'Blogs' ? 'active-nav' : ''}`} style={{color:'black'}} onClick={() => handleMenuClick('Blogs')} id='blog' to="/blogs">Blogs</Link>
                                </li>
                                <li className="nav-item">
                                    <div className="dropdown">
                                        <a className={`nav-link ${activeMenu === 'Contact' ? 'active-nav' : ''} dropdown-toggle`} onClick={() => handleMenuClick('Contact')} style={{color:'black'}} href="/" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                            Contact Us
                                        </a>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                            <li><Link className="dropdown-item" id='email' to="/Contact">Email Us</Link></li>
                                            <li>
                                                <Link className="dropdown-item" to="https://wa.me/7011285005?text=Hello%20there!%20I%20found%20you%20on%20the%20website.%20I%20have%20a%20question." target='_blank'>WhatsApp Us</Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to="tel:917379747622">Call Now</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                            <div className="navbar-nav log" style={{}}>                                
                                { isAuthenticated && <li><strong className='nav-link'>Welcome, {user.name}</strong></li>}
                                { isAuthenticated && 
                                    <li>
                                        <img src={user.picture} alt='' className='profile-pic'></img>
                                    </li>
                                }                                
                                { isAuthenticated ?( 
                                    <li >
                                        <button className='nav-link' onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })} style={{cursor:'pointer', color:'red'}}>
                                            Log Out
                                        </button>
                                    </li>
                                    ) : (
                                        <li>
                                            <button className='nav-link' onClick={() => loginWithRedirect()} style={{cursor:'pointer'}}>Log In</button>
                                        </li>
                                    )
                                }
                                <img width="40" height="40" src={sun} alt="sun" onClick={props.toggle} style={{color: '#fdf73f',cursor:'pointer', backgroundColor: props.mode === 'dark' ? '#A3C1C0' : 'white'}} hidden={props.mode==='dark'?'':'hidden'} />
                                <img width="40" height="40" src={moon} alt="crescent" onClick={props.toggle} style={{color: '#5eb2f3',cursor:'pointer'}} hidden={props.mode==='dark'?'hidden':''}/>
                            </div>
                              
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
}
