import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import {Fade} from "react-awesome-reveal";
import con from '../Home/construction1.svg';
import cond from '../Home/construction1_dark.svg';
import manu from '../Home/manufacturing.svg';
import manud from '../Home/manufacturing_dark.svg';
import imex from '../Home/impexp.svg';
import imexd from '../Home/impexp_dark.svg';


export default function industrySlider(props){
  console.log(props.mode)
  return (
    <div>
      <Carousel data-bs-theme={props.mode === 'dark'?'light':'dark'} className={props.mode==='dark'?'carousld':'carousl'} style={{marginTop:'-5%'}}>
        <Carousel.Item interval={10000}>
          <h3 style={{textAlign:'center', marginTop:'6%'}}>Construction</h3>
          <div className='item-div'>
            <img src={props.mode==='dark'?cond:con} alt="Slide1" id='slide1' className='carImg1'/>
          </div>        
          <Carousel.Caption>
            
            <div className='caption-div'>
              <h3 style={{color:props.mode==='dark'?'#F09F33':'#700567', padding:'10px'}}>We Automate</h3>
              <Fade cascade delay={1000} triggerOnce={true}>
                <li style={{cursor:'pointer'}}>
                  Flow Monitoring System
                </li>
                <li style={{cursor:'pointer'}}>
                  Project Monitoring System
                </li>
                <li style={{cursor:'pointer'}}>
                  Preventive Maintenance Scheduling
                </li>
                <li style={{cursor:'pointer'}}>
                  Human Resource Management System
                </li>
                <li style={{cursor:'pointer'}}>
                  Customised Automation Solutions
                </li>
                <li style={{cursor:'pointer'}}>
                  Inventory Management System
                </li>
                </Fade>
            </div>
            
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={10000}>
          <h3 style={{textAlign:'center', marginTop:'6%'}}>Manufacturing</h3>
          <div className='item-div'>
            <img src={props.mode==='dark'?manud:manu} alt="Slide1" id='slide1' className='carImg'/>
          </div>        
          <Carousel.Caption>
            <div className='caption-div'>
              <h3 style={{color:props.mode==='dark'?'#F09F33':'#700567', padding:'10px'}}>We Automate</h3>
              <Fade cascade delay={1000} triggerOnce={true}>
                <li style={{cursor:'pointer'}}>
                  Flow Monitoring System
                </li>
                <li style={{cursor:'pointer'}}>
                  Product Monitoring System
                </li>
                <li style={{cursor:'pointer'}}>
                  Preventive Maintenance Scheduling
                </li>
                <li style={{cursor:'pointer'}}>
                  Human Resource Management System
                </li>
                <li style={{cursor:'pointer'}}>
                  Inventory Management System
                </li>
                <li style={{cursor:'pointer'}}>
                  Customized Automation Solutions
                </li>
                </Fade>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={10000}>
          <h3 style={{textAlign:'center', marginTop:'6%'}}>Logistics</h3>
          <div className='item-div'>
            <img src={props.mode==='dark'?imexd:imex} alt="Slide1" id='slide1' className='carImg2'/>
          </div>        
          <Carousel.Caption>
            <div className='caption-div'>
              <h3 style={{color:props.mode==='dark'?'#F09F33':'#700567', padding:'10px'}}>We Automate</h3>
              <Fade cascade delay={1000} triggerOnce={true}>
                <li style={{cursor:'pointer'}}>
                  Flow Monitoring System
                </li>
                <li style={{cursor:'pointer'}}>
                  Process Monitoring System
                </li>
                <li style={{cursor:'pointer'}}>
                  Logistics Scheduling
                </li>
                <li style={{cursor:'pointer'}}>
                  Human Resource Management System
                </li>
                <li style={{cursor:'pointer'}}>
                  Inventory Management System
                </li>
                <li style={{cursor:'pointer'}}>
                  Customized Automation Solutions
                </li>
                </Fade>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}
