import React from 'react'

import about from '../Home/About.svg'
import aboutd from '../Home/About_dark.svg'
import mission from './images/mission_info.svg'
import missiond from './images/mission_info_dark.svg'
import vision from './images/vision1.svg'


// var missiondiv = {
//   width: "100%",
//   backgroundImage: "url(" + mission + ")"
// };
export default function About(props) {
  return (
    <>
      <div className='' style={{marginTop:'3.7%'}}>
        <div className="container">
          <div className="row" style={{alignItems: 'center', justifyContent: 'center', display:'flex'}}>
            <div className="col-lg-4">
              <img src={props.mode==='dark'?aboutd:about} alt="About-Us" className='img-about-aboutus' />
            </div>
            <div className="col-lg-8 textdiv-aboutus">            
              <h1 style={{color:props.mode==='dark'?'#F09F33':'#700567'}}>
                Helping Businesses Succeed With<br/>Power Of Automation
              </h1>
              <p>
                At Unreal Automation, we specialize in providing top-notch solutions for all your Google Workspace automation needs. With a team of highly skilled professionals, we are dedicated to streamlining your workflows and maximizing your productivity through automation.
              </p>
            </div>
          </div>
        </div>      
      </div>
      <div className="end-cap ecap"></div> 
      
      <div className="container">
        <h1 style={{color:props.mode==='dark'?'#F09F33':'#700567',justifyContent:'center',textAlign:'center'}} className='rowh1'>The Name</h1>
        <div className="row" style={{alignItems: 'center', justifyContent: 'center', display:'flex'}}>
          <p>
            <span style={{color:props.mode==='dark'?'#F09F33':'#700567'}}><b>Unreal Automation</b></span> wasn't just a name—it embodied our passion. Our journey began with a shared belief in <span style={{color:props.mode==='dark'?'#F09F33':'#700567'}}><b>U</b></span>ser-Centric technology. Each aspect of our mission was a story of dedication.
          </p>
          <p>
            We wanted more than automation; we craved innovation that embraced users' needs. Our <span style={{color:props.mode==='dark'?'#F09F33':'#700567'}}><b>N</b></span>ode-based solutions were interconnected like a family, fostering adaptability and unity.
          </p>
          <p>
            Crafting <span style={{color:props.mode==='dark'?'#F09F33':'#700567'}}><b>R</b></span>esponsive designs and <span style={{color:props.mode==='dark'?'#F09F33':'#700567'}}><b>E</b></span>vent-driven systems wasn't a task; it was an art of anticipating needs and responding with care. <span style={{color:props.mode==='dark'?'#F09F33':'#700567'}}><b>A</b></span>PIs seamlessly integrated, knitting systems together like old friends reunited.
          </p>
          <p>
            <span style={{color:props.mode==='dark'?'#F09F33':'#700567'}}><b>L</b></span>ow-code development wasn't just a tool; it was our gift to democratize tech. We poured our hearts into crafting web apps—interfaces designed to delight and simplify lives.
          </p>
          <p>
            Our commitment was personal. We aimed to revolutionize, empower, and shape a digital realm that felt not just real, but truly <span style={{color:props.mode==='dark'?'#F09F33':'#700567'}}><b>UNREAL</b></span>—full of warmth, accessibility, and love for technology.
          </p>
        </div>
      </div>
      <div className="start-cap scap"></div>
      <div className="container">
        <div className="row" style={{alignItems: 'center', justifyContent: 'center', display:'flex'}}>
          <h1 style={{color:props.mode==='dark'?'#F09F33':'#700567',justifyContent:'center',textAlign:'center'}} className='rowh1'>Mission</h1>
          <img src={props.mode==='dark'?missiond:mission} alt='mission' className='mission-img' />
        </div>
      </div>
      <div className="start-cap scap"></div>
      <div className="container">
        <div className="row" style={{alignItems: 'center', justifyContent: 'center', display:'flex'}}>
          <h1 style={{color:props.mode==='dark'?'#F09F33':'#700567',justifyContent:'center',textAlign:'center'}} className='rowh1'>Vision</h1>
          <div className="col-lg-4">
            <img src={props.mode==='dark'?vision:vision} alt='vision' style={{}} className='img-about-vision' />
          </div>
          <div className="col-lg-8 textdiv-about-vision">
            <p>
              At Unreal Automation, our vision is to be the vanguard of technological evolution, shaping a future where seamless automation and bespoke web applications empower businesses across India.
            </p>
            <p>
              We aspire to create a digital ecosystem where innovation is accessible to all through user-friendly interfaces and cutting-edge technologies.
            </p>
            <p>
              Our commitment to excellence drives us to pioneer solutions that transcend expectations, making us the go-to partner for businesses seeking unparalleled efficiency and digital transformation.
            </p>
            <p>
              We envision a world where UNREAL represents not just a name but a promise – a promise to redefine possibilities, elevate user experiences, and lead India into a new era of technological prowess.
            </p>
          </div>
        </div>
      </div>      
      <div className="container">
        <br />
        <br />
        <br />
      </div>
    </>
  )
}
