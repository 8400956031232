import React from 'react'
import service from '../Home/services1.svg'
import serviced from '../Home/service1_dark.svg'
import ae from './images/automation_excelence.svg'
import wd from './images/web_app_development.svg'
import wdd from './images/web_app_development_dark.svg'
import sc from './images/strategic_consultation.svg'
import scd from './images/strategic_consultation_dark.svg'
import cs from './images/customized_solutions.svg'
import csd from './images/customized_solutions_dark.svg'
import sup from './images/continuous_support.svg'
import supd from './images/continuous_support_dark.svg'
import inn from './images/innovation.svg'
import innd from './images/innovation_dark.svg'
import Card from 'react-bootstrap/Card'


export default function Services(props) {
  return (
    <>
      <div className='' style={{marginTop:'3.7%'}}>
        <div className="container">
          <div className="row" style={{alignItems: 'center', justifyContent: 'center', display:'flex'}}>
            <div className="col-lg-4">
              <img src={props.mode==='dark'?serviced:service} alt="Services" className='img-service-services' />
            </div>
            <div className="col-lg-8 textdiv-services">            
              <h1 style={{color:props.mode==='dark'?'#F09F33':'#700567'}}>
                Discover the Future of Efficiency with<br/>Unreal Automation's Premier Services
              </h1>
              <br/>
              <p>
                At Unreal Automation, we pride ourselves on delivering a diverse array of cutting-edge services aimed at revolutionizing your business operations and enhancing overall productivity. Our team of dedicated professionals is committed to providing tailored solutions that cater to your unique needs. Explore the forefront of innovation with our comprehensive offerings:
              </p>
            </div>
          </div>
        </div>      
      </div>
      <div className="end-cap ecap"></div> 
      
      <div className='container'>        
        <div className="row" style={{marginTop:'-6%'}}>
          <Card className="cols" style={{backgroundColor:props.mode === 'dark'?'#69696900':'white', color:props.mode === 'dark'?'white':'black'}}>
            <Card.Img className='cardImg' src={props.mode==='dark'?ae:ae} alt="Automation Excellence" />
            <Card.ImgOverlay>
              <Card.Title>
                <h3 style={{color:props.mode==='dark'?'#F09F33':'#700567',justifyContent:'center',textAlign:'center', zIndex:100}} className=''>Automation Excellence</h3>
              </Card.Title>
              <Card.Text>
                <br/>
                <p>
                  Streamline your workflows and boost efficiency through our state-of-the-art automation solutions.
                </p>
                <p>
                  From routine tasks to complex processes, we empower your business to operate seamlessly.
                </p>
              </Card.Text>
            </Card.ImgOverlay>
          </Card>
          <Card className="cols" style={{backgroundColor:props.mode === 'dark'?'#69696900':'white', color:props.mode === 'dark'?'white':'black'}}>
            <Card.Img className='cardImg' src={props.mode==='dark'?wdd:wd} alt="Web Application Mastery" />
            <Card.ImgOverlay>
              <Card.Title>
                <h3 style={{color:props.mode==='dark'?'#F09F33':'#700567',justifyContent:'center',textAlign:'center', zIndex:100}} className=''>Web ApplicationMastery</h3>
              </Card.Title>
              <Card.Text>
                <br/>
                <p>
                Unleash the potential of your online presence with our expertise in crafting dynamic and user-centric web applications.
                </p>
                <p>
                Elevate user experiences and stay ahead in the digital landscape.
                </p>
              </Card.Text>
            </Card.ImgOverlay>
          </Card>
        </div>
        <div className="row" style={{paddingTop:'1%'}}>
          <Card className="cols" style={{backgroundColor:props.mode === 'dark'?'#69696900':'white', color:props.mode === 'dark'?'white':'black'}}>
            <Card.Img className='cardImg' src={props.mode==='dark'?scd:sc} alt="Strategic Consultation" />
            <Card.ImgOverlay>
              <Card.Title>
                <h3 style={{color:props.mode==='dark'?'#F09F33':'#700567',justifyContent:'center',textAlign:'center', zIndex:100}} className=''>Strategic Consultation</h3>
              </Card.Title>
              <Card.Text>
                <br/>
                <p>
                Benefit from our seasoned consultants who guide you through the strategic implementation of automation and web solutions, ensuring alignment with your business goals.
                </p>
                
              </Card.Text>
            </Card.ImgOverlay>
          </Card>
          <Card className="cols" style={{backgroundColor:props.mode === 'dark'?'#69696900':'white', color:props.mode === 'dark'?'white':'black'}}>
            <Card.Img className='cardImg' src={props.mode==='dark'?csd:cs} alt="Customized Solutions" />
            <Card.ImgOverlay>
              <Card.Title>
                <h3 style={{color:props.mode==='dark'?'#F09F33':'#700567',justifyContent:'center',textAlign:'center', zIndex:100}} className=''>Customized Solutions</h3>
              </Card.Title>
              <Card.Text>
                <br/>
                <p>
                  Every business is unique, and so are our solutions. We offer tailor-made services that address your specific challenges, providing bespoke automation and web applications to meet your requirements.
                </p>
              </Card.Text>
            </Card.ImgOverlay>
          </Card>
        </div>
        <div className="row" style={{paddingTop:'1%',alignItems:'center', alignContent:'center', textAlign:'center'}}>
          <Card className="cols_full" style={{backgroundColor:props.mode === 'dark'?'#69696900':'white', color:props.mode === 'dark'?'white':'black'}}>
            <Card.Img className='cardImg_full' src={props.mode==='dark'?supd:sup} alt="Continuous Support" />
            <Card.ImgOverlay>
              <Card.Text style={{verticalAlign:'middle', marginTop:'5%'}}>
                <h3 style={{color:props.mode==='dark'?'#F09F33':'#700567',justifyContent:'center',textAlign:'center', zIndex:100}} className=''>Continuous Support</h3>
              {/* </Card.Title>
              <Card.Text> */}
                <br/>
                <p>
                  Our commitment extends beyond project delivery. Experience unparalleled support and maintenance services, ensuring your systems operate seamlessly and stay up-to-date.
                </p>                
              </Card.Text>
            </Card.ImgOverlay>
          </Card>
          </div>
          <div className='row' style={{paddingTop:'1%'}}>
          {/* <Card className="cols_full" style={{backgroundColor:props.mode === 'dark'?'black':'white', color:props.mode === 'dark'?'white':'black'}}>
            <Card.Img className='cardImg_full' src={props.mode==='dark'?innd:inn} alt="Customized Solutions" />
            <Card.ImgOverlay>
              <Card.Text> */}
                <h2 style={{color:props.mode==='dark'?'#F09F33':'#700567',justifyContent:'center',textAlign:'center', zIndex:100, marginTop:'7%'}}>
                  Embark on a journey of transformation with Unreal Automation – where innovation meets practicality.
                </h2>
              {/* </Card.Text>
            </Card.ImgOverlay>
          </Card> */}
        </div>
      </div>
    </>
  )
}
