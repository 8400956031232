import React, {useState, useEffect} from 'react'
import about from './About.svg';
import about_dark from './About_dark.svg';
import service from "./services1.svg";
import service_dark from "./service1_dark.svg";
import {Fade, Slide} from "react-awesome-reveal";
import { Link } from 'react-router-dom';
import IndSlider from '../Slider/IndSlider';
import ImageSlider from '../Slider/Slider';
import projectIcon from './icons/projectIcon1.png';
import ontimeIcon from './icons/ontimeIcon.png';
import experienceIcon from './icons/experienceIcon.png';
import clientsIcon from './icons/clientsIcon.png';
import supportIcon from './icons/supportIcon.png';
import userIcon from './icons/usersIcon.png';
import teamIcon from './icons/teamIcon.png';
import visitorIcon from './icons/visitorIcon.png'


export default function Home(props) {
  console.log(props.mode)
  const [activeMenu, setActiveMenu] = useState(null);

    const handleMenuClick = (menuName) => {
        setActiveMenu(menuName);
        localStorage.setItem('activeMenu', menuName);
    };

    useEffect(() => {
        const storedActiveMenu = localStorage.getItem('activeMenu');
        if (storedActiveMenu) {
            setActiveMenu(storedActiveMenu);
        }
        const currentPath = window.location.pathname;

        if (currentPath === '/') {
            setActiveMenu('Home');
        } else if (currentPath === '/about') {
            setActiveMenu('About');
        } else if (currentPath === '/Contact') {
            setActiveMenu('Contact');
        } else if (currentPath === '/services') {
            setActiveMenu('Services');
        }
    }, []);
  return (
    <>
    
      <div id='section0'>
        <ImageSlider mode={props.mode}/>
      </div>
      <div id='section2' style={{padding:'2% 0'}}>
        <div className='container'>
          <div className="row">
            <div className="col-lg-4" style={{alignItems: 'center', justifyContent: 'center', display:'flex'}}>
              <Slide duration={6000} direction='left' triggerOnce={true}>
                <img src={props.mode==='dark'?about_dark:about} alt="Automation" className='imgabout' />
              </Slide>
            </div>
            <div className="col-lg-8 textdiv">
              <h1 style={{color:props.mode==='dark'?'#F09F33':'#700567'}}>About Us</h1>
              <Slide duration={5000} direction='right' triggerOnce={true}>
                {/* <Fade duration={6000}> */}
                  <p>
                    At Unreal Automation, we specialize in providing top-notch solutions for all your Google Workspace automation needs. With a team of highly skilled professionals, we are dedicated to streamlining your workflows and maximizing your productivity through automation.
                  </p>
                {/* </Fade> */}
              </Slide>
              <Fade duration={6000} className='py-4' triggerOnce={true}>
                <button type="button" className={props.mode==='dark'?'btn btndark':'btn btnlight'} onClick={() => handleMenuClick('About')}>
                  <Link className='text-white nav-link' to={'./about'} onClick={() => handleMenuClick('About')}>Read More</Link>
                </button>
              </Fade>
            </div>
          </div>
        </div>
      </div>
      <div className="start-cap scap"></div>
      <div id="section1" style={{padding:'2% 0'}}>
        <div className='container'>
          <div className="row">
            <div className="col-lg-8 textdiv">
            <h1 className='h1text' style={{color:props.mode==='dark'?'#F09F33':'#700567'}}>Services</h1>
              <Slide duration={5000} triggerOnce={true}>
                {/* <Fade duration={6000}> */}
                  <p>
                    Unreal Automation offers a range of comprehensive services designed to empower your business with efficient automation and cutting-edge web applications. Our team of dedicated professionals is committed to delivering solutions that transform the way you work and maximize your productivity.
                  </p> 
                {/* </Fade> */}
              </Slide>
              <Fade duration={6000} className='py-4' triggerOnce={true}>
                <button type="button" className={props.mode==='dark'?'btn btndark':'btn btnlight'}>
                  <Link className='text-white nav-link' to={'./services'}>Find Out More</Link>
                </button>
              </Fade>
            </div>
            <div className="col-lg-4" style={{alignItems: 'center', justifyContent: 'center', display:'flex'}}>
              <Slide duration={6000} direction='right' triggerOnce={true}>
                <img src={props.mode==='dark'?service_dark:service} alt="Workflow" className='imgservice' style={{}} />
              </Slide>
            </div>
          </div>
        </div>
      </div>
      <div className="end-cap ecap" style={{marginTop:'5%'}}></div>
      <div id='section4'>
          <div className="row rowh1">
            <h1 style={{color:props.mode==='dark'?'#F09F33':'#700567', textAlign:'center'}}>Industries</h1>
          </div>
          <IndSlider mode={props.mode} />
      </div>
      <div className="start-cap scap"></div>
      <div id='section5'>
        <div className='container' style={{padding:'6% 0'}}>
          <div className="row " style={{margin:'-2% 0 2% 0'}}>
            <h1 style={{color:props.mode==='dark'?'#F09F33':'#700567', textAlign:'center'}}>Why Choose Unreal Automation</h1>
          </div>
          <div className="row">
            <div className="col-12 colh p-4 col-lg-3">
              <Fade cascade duration={1000} triggerOnce={true}>
              <div className="row items-center justify-center">
                <div className="col-4 flex flex-col items-center md:col-span-1">
                  <img src={ontimeIcon} alt="logo" height={80} width={80} />
                </div>
                <div className="col-8 text-left">
                  <Slide cascade damping={0.1} fraction={0.5} triggerOnce={true}>
                  <h3 style={{color:props.mode==='dark'?'#F09F33':'#700567'}}>On-Time</h3>
                  <p style={{fontSize:'16px'}}>Project Delivery</p>
                  </Slide>
                </div>
              </div>
              </Fade>
            </div>
            <div className="col-12 colh p-4 col-lg-3">
            <Fade cascade duration={1000} delay={1000} triggerOnce={true}>
              <div className="row items-center justify-center">
                <div className="col-4 flex flex-col items-center md:col-span-1">
                  <img src={experienceIcon} alt="logo" height={80} width={80} />
                </div>
                <div className="col-8 text-left">
                <Slide cascade damping={0.1} delay={1000} fraction={0.5} triggerOnce={true}>
                  <h3 style={{color:props.mode==='dark'?'#F09F33':'#700567'}}>6+ Years</h3>
                  <p style={{fontSize:'16px'}}>Of Experience</p>
                  </Slide>
                </div>
              </div>
              </Fade>
            </div>
            <div className="col-12 colh p-4 col-lg-3">
            <Fade cascade duration={1000} delay={2000} triggerOnce={true}>
              <div className="row items-center justify-center">
                <div className="col-4 flex flex-col items-center md:col-span-1">
                  <img src={clientsIcon} alt="logo" height={80} width={80} />
                </div>
                <div className="col-8 text-left">
                <Slide cascade damping={0.1} delay={2000} fraction={0.5} triggerOnce={true}>
                  <h3 style={{color:props.mode==='dark'?'#F09F33':'#700567'}}>8+ </h3>
                  <p style={{fontSize:'16px'}}>Happy Clients</p>
                  </Slide>
                </div>
              </div>
              </Fade>
            </div>
            <div className="col-12 colh p-4 col-lg-3">
            <Fade cascade duration={1000} delay={3000} triggerOnce={true}>
              <div className="row items-center justify-center">
                <div className="col-4 flex flex-col items-center md:col-span-1">
                  <img src={projectIcon} alt="logo" height={80} width={80} />
                </div>
                <div className="col-8 text-left">
                <Slide cascade damping={0.1} delay={3000} fraction={0.5} triggerOnce={true}>
                  <h3 style={{color:props.mode==='dark'?'#F09F33':'#700567'}}>25+</h3>
                  <p style={{fontSize:'16px'}}>Projects Completed</p>
                  </Slide>
                </div>
              </div>
              </Fade>
            </div>
          </div>
          <div className="row">
            <div className="col-12 p-4 colh col-lg-3">
            <Fade cascade duration={1000} triggerOnce={true}>
              <div className="row items-center justify-center">
                <div className="col-4 flex flex-col items-center md:col-span-1">
                  <img src={supportIcon} alt="logo" height={80} width={80} />
                </div>
                <div className="col-8 text-left">
                <Slide cascade damping={0.1} fraction={0.5} triggerOnce={true}>
                  <h3 style={{color:props.mode==='dark'?'#F09F33':'#700567'}}>24*7</h3>
                  <p style={{fontSize:'16px'}}>Support</p>
                  </Slide>
                </div>
              </div>
              </Fade>
            </div>
            <div className="col-12 p-4 colh col-lg-3">
            <Fade cascade duration={1000} delay={1000} triggerOnce={true}>
              <div className="row items-center justify-center">
                <div className="col-4 flex flex-col items-center md:col-span-1">
                  <img src={userIcon} alt="logo" height={80} width={80} />
                </div>
                <div className="col-8 text-left">
                <Slide cascade damping={0.1} delay={1000} fraction={0.5} triggerOnce={true}>
                  <h3 style={{color:props.mode==='dark'?'#F09F33':'#700567'}}>1300+</h3>
                  <p style={{fontSize:'16px'}}>Happy Users</p>
                  </Slide>
                </div>
              </div>
              </Fade>
            </div>
            <div className="col-12 p-4 colh col-lg-3">
            <Fade cascade duration={1000} delay={2000} triggerOnce={true}>
              <div className="row items-center justify-center">
                <div className="col-4 flex flex-col items-center md:col-span-1">
                  <img src={teamIcon} alt="logo" height={80} width={80} />
                </div>
                <div className="col-8 text-left">
                <Slide cascade damping={0.1} delay={2000} fraction={0.5} triggerOnce={true}>
                  <h3 style={{color:props.mode==='dark'?'#F09F33':'#700567'}}>5+</h3>
                  <p style={{fontSize:'16px'}}>Teams Working</p>
                  </Slide>
                </div>
              </div>
              </Fade>
            </div>
            <div className="col-12 p-4 colh col-lg-3">
            <Fade cascade duration={1000} delay={3000} triggerOnce={true}>
              <div className="row items-center justify-center">
                <div className="col-4 flex flex-col items-center md:col-span-1">
                  <img src={visitorIcon} alt="logo" height={80} width={80} />
                </div>
                <div className="col-8 text-left">
                <Slide cascade damping={0.1} delay={3000} fraction={1} triggerOnce={true}>
                  <h3 style={{color:props.mode==='dark'?'#F09F33':'#700567'}}>10000+</h3>
                  <p style={{fontSize:'16px'}}>Unique Visitors</p>
                  </Slide>
                </div>
              </div>
              </Fade>
            </div>
          </div>          
        </div>
      </div>
    </>
  )
}
