import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import png1 from "../../slides/workflow.png";
import png1d from "../../slides/workflow_dark.png"
import png2 from "../../slides/automation.png";
import png2d from "../../slides/automation_dark.png";
import png3 from "../../slides/api.png";
import png3d from "../../slides/api_dark.png";
import png4 from "../../slides/analytics.png";
import png4d  from "../../slides/analytics_dark.png";

export default function imageSlider(props){
  console.log(props.mode)
  return (
    <div>
    <Carousel data-bs-theme={props.mode === 'dark'?'light':'dark'} style={{boxShadow:'0px 10px 10px 10px silver'}}>
      <Carousel.Item interval={10000}>
        <div className='carousel-item-div'>
          <img src={props.mode==='dark'?png1d:png1} alt="Slide1" id='slide1' height={'100%'} width={'100%'}/>
        </div>        
        <Carousel.Caption>
          <div className='carousel-caption-div'>
            <h3 style={{color:props.mode==='dark'?'#F09F33':'#700567', padding:'10px'}}>Streamline Your Workflows</h3>
            <p style={{color:props.mode==='dark'?'white':'black', padding:'10px'}}>
              Unreal Automation's team of experts specializes in Google Workspace automation, helping you automate repetitive tasks, eliminate errors, and achieve optimal efficiency. Say goodbye to manual data entry and complex calculations, and say hello to streamlined workflows that save you time and effort.</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={10000}>
        <div className='carousel-item-div'>
          <img src={props.mode==='dark'?png2d:png2} alt="Slide2" height={'100%'} width={'100%'} />
        </div>
        <Carousel.Caption>
          <div className='carousel-caption-div'>
            <h3 style={{color:props.mode==='dark'?'#F09F33':'#700567', padding:'10px'}}>Unleash the Power of Automation</h3>
            <p style={{color:props.mode==='dark'?'white':'black', padding:'10px'}}>With our comprehensive understanding of business processes, we can develop customized automation solutions tailored to your specific needs. Our automation techniques will free up your team's time and allow them to focus on strategic initiatives.</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={10000}>
        <div className='carousel-item-div'>
          <img src={props.mode==='dark'?png3d:png3} alt="Slide3" height={'100%'} width={'100%'} />
        </div>
        
        <Carousel.Caption>
        <div className='carousel-caption-div'>
          <h3 style={{color:props.mode==='dark'?'#F09F33':'#700567', padding:'10px'}}>Seamless Integrations</h3>
          <p style={{color:props.mode==='dark'?'white':'black', padding:'10px'}}>
          We understand that your business relies on a variety of tools and platforms to operate effectively. That's why our automation solutions seamlessly integrate with your existing systems. Our goal is to provide you with a unified ecosystem that works harmoniously to drive your success.
          </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={10000}>
        <div className='carousel-item-div'>
          <img src={props.mode==='dark'?png4d:png4} alt="Slide3" height={'100%'} width={'100%'} />
        </div>
        
        <Carousel.Caption>
        <div className='carousel-caption-div'>
          <h3 style={{color:props.mode==='dark'?'#F09F33':'#700567', padding:'10px'}}>Advanced Analytics and Insights</h3>
          <p style={{color:props.mode==='dark'?'white':'black', padding:'10px'}}>
          Our automation solutions enable you to extract valuable information from your Google Sheets, visualize trends, and make data-driven decisions. Gain actionable insights that fuel growth, optimize processes, and improve your bottom line.
          </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
   </div>
  );
}
