import React from 'react'
import fLogo from '../../media/favicon-32x32.png'


export default function Footer(props) {
  return (
    <>
        <div className="container-fluid" style={{backgroundColor:props.mode==='dark'?'#A3C1C0':'white',boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',fontWeight:600}}>
            <footer className="d-flex flex-wrap py-3 my-6 border-top">
                <div className=" col-md-4 mx-4 d-flex align-items-left">
                    <a href="/" className="fcopy mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">
                        <img src={fLogo} alt='' />
                    </a>
                    <span className="fcopy mb-3 mb-md-0" style={{marginTop:'1%'}}>© 2019 - {(new Date().getFullYear())} The Unreals</span>
                </div>
                <div className="navbar-nav flog" style={{marginTop:'-2%'}}>
                <ul className="nav flog">
                    <li className='nav-item'>
                        <a className='text-muted nav-link' href="https://wa.me/7011285005?text=Hello%20there!%20I%20found%20you%20on%20the%20website.%20I%20have%20a%20question." target='_blank' rel='noreferrer'>
                            <i className="fa-brands fa-whatsapp fa-xl" style={{color: "#0cd709"}}></i>
                        </a>
                    </li>
                    <li className='nav-item'>
                        <a className='text-muted nav-link' href="tel:917379747622">
                            <i className="fa-solid fa-phone fa-xl" style={{color: "#3dc4e6"}}></i>
                        </a>
                    </li>
                    <li className='nav-item'>
                        <a className="text-muted nav-link" href="https://www.facebook.com/profile.php?id=100078450640858">
                            <i className="fa-brands fa-facebook fa-xl" style={{color: '#063484'}}></i>
                        </a>
                    </li>
                    <li className='nav-item'>
                        <a className="text-muted nav-link" href="https://www.youtube.com/@TheUnreals">
                            <i className="fa-brands fa-youtube fa-xl" style={{color: 'red'}}></i>
                        </a>
                    </li>
                    <li className='nav-item'>
                        <a className="text-muted nav-link" href="/">
                            <i className="fa-brands fa-twitter fa-xl" style={{color: '#063484'}}></i>
                        </a>
                    </li>
                    <li className='nav-item'>
                        <a className="text-muted nav-link" href="/">
                            <i className="fa-brands fa-instagram fa-xl" style={{color: 'red'}}></i>
                        </a>
                    </li>
                    <li className='nav-item'>
                        <a className="text-muted nav-link" href="/">
                            <i className="fa-brands fa-linkedin fa-xl" style={{color: '#063484'}}></i>
                        </a>
                    </li>
                </ul>
                </div>
            </footer>
        </div>
    </>
  )
}
